import { Link } from "gatsby";
import React, { Fragment } from "react";
import NewCards from "../card/newcards";

type Actualite = {
    title: string;
    dataActivite: any;
};

const ListActivite: React.FC<Actualite> = ({ title, dataActivite }) => {
    return (
        <div className="tw-space-y-4 tw-flex tw-justify-center">
            <div className="">
                <div
                    className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 xl:tw-grid-cols-3 tw-gap-12 tw-mt-2"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                    }}
                >
                    {dataActivite &&
                        dataActivite.map((item: any, index: number) => {
                            return (
                                <div
                                    key={index}
                                    className={
                                        index + 1 < dataActivite.length
                                            ? "tw-border-r-2  "
                                            : ""
                                    }
                                >
                                    <NewCards
                                        content={item.content}
                                        image={item.image}
                                        title={item.title}
                                        idNews={item.id}
                                        categoriesID={
                                            item.categories[0].categories_id.id
                                        }
                                    />
                                </div>
                            );
                        })}
                    {dataActivite.length == 2 && (
                        <div className="tw-block tw-w-80 tw-h-3" />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ListActivite;
