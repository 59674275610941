import React, { useEffect, useState } from "react";
import { debounce } from "lodash";

import SearchBarActualite from "../components/search/search-bar-actualite";
import Layout from "../layouts/layout";
import ListActivite from "../components/activite/activite";
import { graphql, Link, PageProps } from "gatsby";
import { formatFilterText, itextContains } from "../utils/utils";
import Presentation from "../components/about/presentation";

type DataProps = {
    directus: {
        news: [
            content: any,
            id: string,
            image: {},
            date_published: string,
            categories: {},
            title: string
        ];
    };
};

const About = ({ data }: PageProps<DataProps>) => {
    const [dataActivite, setDataActivite] = useState<any[]>([]);
    const [dataActiviteFilter, setDataActiviteFilter] = useState<any[]>([]);
    const [textFilter, setTextFilter] = useState("");

    const newsLimit = parseInt(process.env.NEWS_LIMIT!);

    useEffect(() => {
        let aNews = data.directus.news;
        setDataActivite(aNews);
        setDataActiviteFilter(aNews.slice(0, newsLimit));
    }, []);

    const onChangeFilter = debounce((strTextFilter: string) => {
        let aFilterData: any[] = [];

        strTextFilter = formatFilterText(strTextFilter);
        if (strTextFilter) {
            dataActivite.map((data) => {
                if (
                    itextContains(data.content, strTextFilter) ||
                    itextContains(data.title, strTextFilter)
                ) {
                    aFilterData.push(data);
                }
            });
        } else {
            aFilterData = dataActivite.slice(0, newsLimit);
        }

        setDataActiviteFilter(aFilterData);
        setTextFilter(strTextFilter);
    }, 750);

    return (
        <Layout>
            <div className="tw-bg-bgColor">
                <Presentation />

                <div className="tw-bg-white tw-px-5">
                    <div className="tw-w-full tw-items-center tw-py-4">
                        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
                            <p className="tw-text-2xl tw-font-bold tw-pb-1 tw-text-primary">
                                Activités récentes
                            </p>
                            <p className="tw-pb-1 tw-max-w-xl tw-text-center">
                                Lorem ipsum dolor sit amet consectetur,
                                adipisicing elit. Optio hic quos quasi minima,
                                neque voluptatum molestiae deserunt tempora sunt
                                possimus et quis? Minima, fugiat veniam labore
                                dicta voluptatibus dignissimos molestiae.
                            </p>
                        </div>
                    </div>
                    <div className="tw-space-y-4 tw-flex tw-justify-center">
                        <div className="tw-mt-5 tw-w-[75%]">
                            <SearchBarActualite
                                onChange={onChangeFilter}
                                textFilter={textFilter}
                                placeholder="Rechercher des activités"
                            />
                        </div>
                    </div>
                    <div className="tw-mb-11 tw-mt-4">
                        <ListActivite
                            title="Activités récentes"
                            dataActivite={dataActiviteFilter}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default About;

export const query = graphql`
    query directUSActivite {
        directus {
            news(
                filter: {
                    categories: { categories_id: { id: { _eq: "1" } } }
                    status: { _eq: "published" }
                }
            ) {
                id
                content
                image {
                    id
                    filename_download
                    imageFile {
                        publicURL
                    }
                }
                date_published
                categories {
                    categories_id {
                        id
                        name
                    }
                }
                title
            }
        }
    }
`;
