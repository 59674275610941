import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import File from "../file";

export default function Presentation() {
    const data = useStaticQuery(graphql`
        query {
            directus {
                contents(
                    filter: {
                        code: { code: { _eq: "aPropos-bd-adresse" } }
                        page: { code: { _eq: "APropos" } }
                    }
                ) {
                    text
                    id
                    file {
                        imageFile {
                            publicURL
                        }
                        id
                    }
                }
            }
        }
    `);

    const contents = data.directus.contents;
    let content;
    if (contents.length) {
        content = contents[0];
    }

    return (
        <>
            <div className="tw-w-full  tw-bg-slate-600 tw-h-[600px]">
                <File
                    filename={content?.file?.imageFile?.publicURL}
                    className="!tw-h-[600px]"
                />
            </div>
            <div className="tw-px-7 tw-pb-8 ">
                <div
                    className="tw-p-5 custom-content"
                    dangerouslySetInnerHTML={{ __html: content?.text }}
                ></div>
            </div>
        </>
    );
}
